import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#999999',
      neutralLight2: '#FAF9F7',
      neutralLight1: '#D8D8D8',
      neutralDark1: '#000000',
      primaryDark: '#26292C',
      primaryLight: '#D4C0AA',
      dangerLight: '#DB4401',
    },
  },
  fontFamily: {
    heading: "'orpheuspro', serif",
    paragraph: "'nimbus-sans', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
